<!--设备模块--实际数据--趋势图-->
<template>
  <div v-loading="loading"
    id="timeSetupTrendChart"    
    :element-loading-text="$t('terminaleditor.a1')"
    ref="timeSetupTrendChart"
  >
    <!-- <div class="header">
      <my-headertitle :quit="true"
        >{{name}}
        <template v-slot:name>
          <a-icon
            type="pushpin"
            :style="{ 'margin-right': '5px', color: '#7682CE' }"
          />
          <span>{{nodeName}}</span>
        </template>
      </my-headertitle>
    </div>     -->
    <div class="main">   
      <!-- 曲线图 -->
        <div id="myChart" v-if="isShow" :style="{width: '100%', height: '400px'}"></div>
    </div>
    <div class="footer">
      <a-button
        type="primary"        
        @click="reload"
        >{{$t('dataCompareTrendList.a3')}}</a-button
      >
    </div>    
  </div>
</template>

<script>

import headerTitle from "../../../components/Title/headerTitle";
import { getTimeTrendData } from "../../../api/device";

export default {  
  props:{
    signalPoints:Array ,
    axisformat:{},
  },
  data() {
    return {
      isShow:false,
      loading:false,
      trend:{},
      dataList: [],
    };
  },
  created(){
  },
  mounted() {   
    this.getData();     
  },
  watch: {     
    isShow (val, oldVal) {
      if (val) {
          this.sleep(this.time).then(()=>{         
            this.drawLine();            
          })       
      }
    },
    pluginsDataChange (newval, oldval) {
      this.reload();      
    }
  },
  computed: {
    pluginsDataChange () {
        return JSON.stringify(this.axisformat)
    },    
    
  },
  methods: {
    sleep(ms) {
      return new Promise(resolve => 
        setTimeout(resolve, ms)
       )
    },
    reload(){//重新加载数据
      this.isShow=false;
      this.getData();  
    }, 
    // 页面后退
    goBack() {
      this.$router.go(-1);
    },
    // 获取当前已注册的设备信号列表
    getData() {
      let tagPoints="";
      let tagPoint = "";
      this.loading=true;
      if(this.signalPoints!=null && this.signalPoints.length>0){
        for(let i=0;i<this.signalPoints.length;i++){
          tagPoint = this.signalPoints[i].signalNo+"@@"+this.signalPoints[i].signalKind+"@@"+this.signalPoints[i].trendType;
          if(tagPoints=="")
            tagPoints = tagPoint;
          else
            tagPoints = tagPoints+","+tagPoint;
        }
      }
      
      let par = {
        tagPoints: tagPoints,
        date: this.axisformat.dateString,
        time: this.axisformat.timeString,
        div: this.axisformat.timeView,
        pvtype: 1
      };       
      console.log("getTrendData par",par); 
      getTimeTrendData(par)
      .then((res) => {
        let { data } = res; 
        this.dataList=data; 
        if(this.dataList!=null&&this.dataList.length>0){
          for(let i=0;i<this.dataList.length;i++){
            this.dataList[i].key=i;
          }
        }        
        this.isShow=true;
        console.log("getTrendData：",this.dataList);                  
      })
      .catch((err) => {
        console.log(err);
        }); 
      this.loading=false;        
    },  
    chartResize(){
        try{
            let o = document.getElementById("myChart");
            if(o!=null){
                this.$echarts.init(document.getElementById("myChart")).resize();
            }
        }catch(e){
            console.log(e);
        } 
    },
    getNumber(value){
      let statelist = {};
      if(this.signalPoints!=null &&this.signalPoints.length>0)
        statelist = this.signalPoints[0].multiStateList;               
      if(statelist!=null&&statelist.length>0){
        for(let i=0;i<statelist.length;i++){                                  
            if(value===statelist[i].text){
              return statelist[i].no;
            }                
        }            
      }        
      return value;     
    },
    drawLine() {
      // 基于准备好的dom，初始化echarts实例
      // console.log("drawLine signalPoints",this.signalPoints);
      let myChart = this.$echarts.init(document.getElementById("myChart"));
      let legendData=[];
      let categories = []; 
      let signalKind="";     
      let statelist = {};
      if(this.signalPoints!=null &&this.signalPoints.length>0){
        signalKind=this.signalPoints[0].signalKind;
        statelist = this.signalPoints[0].multiStateList;
        for(let i=0;i<this.signalPoints.length;i++){
          legendData.push(this.signalPoints[i].signalName);          
        }
      }
      
      let valueData=[];      
      if(this.dataList!=null&&this.dataList.length>0){
        let value=0;        
        for(let i=0;i<this.dataList.length;i++){
          //取x轴坐标
          categories.push(this.dataList[i].time);          
          //取y轴数据值
          //取y轴数据值
          if(this.dataList[i].value1){//如果存在value1
            if(!valueData[0]){
              valueData[0]=[];
            }
            valueData[0].push(this.getNumber(this.dataList[i].value1));
          }
          if(this.dataList[i].value2){//如果存在value2
            if(!valueData[1]){
              valueData[1]=[];
            }
            valueData[1].push(this.getNumber(this.dataList[i].value2));
          }
          if(this.dataList[i].value3){//如果存在value3
            if(!valueData[2]){
              valueData[2]=[];
            }
            value=this.dataList[i].value3;
            valueData[2].push(this.getNumber(value));
          }
          if(this.dataList[i].value4){//如果存在value4
            if(!valueData[3]){
              valueData[3]=[];
            }
            value=this.dataList[i].value4;
            valueData[3].push(this.getNumber(value));
          }
          if(this.dataList[i].value5){//如果存在value5
            if(!valueData[4]){
              valueData[4]=[];
            }
            value=this.dataList[i].value5;
            valueData[4].push(this.getNumber(value));
          }
          
        }
        console.log("drawLine valueData",valueData); 
      }

      let series=[];
      let serie = null;
      
      if(valueData.length>0){
        if(this.signalPoints!=null &&this.signalPoints.length>0){
          for(let i=0;i<this.signalPoints.length;i++){
            serie = {
                name: this.signalPoints[i].signalName,
                type: 'line',
                // stack: '总量',
                data: valueData[i]
            };
            series.push(serie);
          }
        }
      }
      var yAxis={};      
      var unit = "";
      if(this.signalPoints!=null &&this.signalPoints.length>0){
        unit=this.signalPoints[0].unit;
      }
      if(signalKind=="analog"){
        yAxis={
              type: 'value',  
              formatter: '{value}'+ unit,       
          };
      }
      else{
        yAxis={
              type: 'value',
              min: 0,
              max: 4,
              axisLabel:{
                formatter:function(value){  
                  var texts=[];                  
                  if(statelist!=null&&statelist.length>0){
                    for(let i=0;i<statelist.length;i++){                                  
                        if(value+""===statelist[i].no){
                          texts.push(statelist[i].text);
                        }                
                    }            
                  }   
                  
                return texts;  
                }
              },             
        };
      }

      // 绘制图表
      let option = {
          title: {
              text: '时间设置趋势图'
          },
          tooltip: {
              trigger: 'axis'
          },
          legend: {
              data: legendData
          },
          grid: {
              left: '3%',
              right: '4%',
              bottom: '3%',
              containLabel: true
          },
          toolbox: {
              feature: {
                  saveAsImage: {}
              }
          },
          xAxis: {
              type: 'category',
              boundaryGap: false,
              data: categories
          },
          yAxis:yAxis,
          series: series
      };      
      
      // 使用刚指定的配置项和数据显示图表。
      myChart.setOption(option);        
    },
  },
  components: {
    "my-headertitle": headerTitle,
  },
};
</script>

<style scoped>
div /deep/.ant-modal-header {
  background-color: #7682ce;
}
#timeSetupTrendChart {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  overflow: auto;
}
.header {
  height: 30px;
  padding: 10px 20px 0px 20px;
}
.main {
  width: 100%;
  /* height: calc(100% - 113px); */
  padding: 10px 20px;
  overflow: auto;
}
.footer {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0px 20px;
  overflow: hidden;
  border-top: 1px solid rgba(12, 12, 12, 0.1);
}
.footer button {
  margin-left: 10px;
  width: 120px;
}
</style>