<!--设备模块--时间设置趋势图表-->
<template>
  <div id="timeSetupTrend" ref="timeSetupTrend">
    <div class="aside">
      <a-spin :spinning="spinning" style="width: 100%; height: 100%">
        <my-navder
          :list="list"
          @select="getMsgFormSon"
          :style="style"
        ></my-navder>
      </a-spin>
    </div>
    <div class="right">
      <a-spin :spinning="loading" class="main-spinning" v-if="id">
        <div class="main">          
          <my-headertitle>{{$t('timeSetupTrend.a1')}}</my-headertitle>
          <div class="info">
            <div>
              <span>{{$t('timeSetupTrend.a2')}}</span>
              <span>{{ nodeName }}</span>        
            </div>
            <div>
              <span>{{$t('timeSetupTrend.a3')}}</span>
              <span>{{ nodeId }}</span>
            </div>
          </div>
          <!-- <my-tabletitle>信号点选择</my-tabletitle> -->
          <a-table 
            size="small"
            :columns="columns" 
            :data-source="infoList"   
            :pagination=false                   
            style="margin: 20px 10px;"
            >
          </a-table>  
          <a-form-model :model="form" :label-col="labelCol" :wrapper-col="wrapperCol" >
            <a-form-model-item :label="$t('timeSetupTrend.a4')">
              <a-select v-model="form.timeView" placeholder="$t('timeSetupTrend.a5')" style="width: 200px">
                <a-select-option :value="$t('timeSetupTrend.a6')">
                  {{$t('timeSetupTrend.a6')}}
                </a-select-option>
                <a-select-option :value="$t('timeSetupTrend.a7')">
                  {{$t('timeSetupTrend.a7')}}
                </a-select-option>
              </a-select>
            </a-form-model-item>
            <a-form-model-item :label="$t('timeSetupTrend.a8')">
              <a-date-picker
                v-model="form.date"
                type="date"
                style="width: 200px"
              />
            </a-form-model-item>    
            <a-form-model-item :label="$t('timeSetupTrend.a9')">
              <a-time-picker 
              v-model="form.time"
              :default-value="moment('4:00', 'HH:mm')" 
              format="HH:mm"
               style="width: 200px"
              />   
            </a-form-model-item> 
            <a-form-model-item :label="$t('timeSetupTrend.a10')">           
              <a-table 
                :columns="formatColumns" 
                :data-source="form.formatList"  
                :pagination=false           
                >
                  <template slot="unit" slot-scope="record">
                      <!-- <a-select default-value="$t('timeSetupTrend.a11')" v-model="record.unit" @change="handleChange" style="width: 80px">
                        <a-select-option value="$t('timeSetupTrend.a11')">
                          {{$t('timeSetupTrend.a11')}}
                        </a-select-option>
                        <a-select-option value="℃">
                          ℃
                        </a-select-option>
                        <a-select-option value="$t('timeSetupTrend.a12')">
                          {{$t('timeSetupTrend.a12')}}
                        </a-select-option>
                      </a-select>          -->
                       <a-select v-model="record.unit" @change="handleChange" style="width: 80px">
                         <a-select-option :value="item.no" v-for="(item,index) in units" :key="index">{{item.text}}</a-select-option>
                       </a-select>
                  </template>
                  <template slot="max" slot-scope="record">
                    <a-input v-model="record.max" style="width: 80px"/>
                  </template>   
                  <template slot="min" slot-scope="record">
                    <a-input v-model="record.min" style="width: 80px"/>
                  </template>  
                  <template slot="count" slot-scope="record">
                      <a-select @change="handleChange" v-model="record.count" style="width: 80px">
                        <a-select-option v-for="item in countList" :key="item.key">
                          {{ item.value }}
                        </a-select-option>
                      </a-select>         
                  </template>          
              </a-table> 
            </a-form-model-item>
          </a-form-model>   
        </div>
      </a-spin>
      <div class="main-show" v-else>
        <a-icon type="select" style="font-size: 60px; margin-bottom: 20px" />
        <span>{{$t('timeSetupTrend.a13')}}</span>
      </div>
      <div class="footer" v-if="id">
        <a-button type="primary" @click="showList">{{$t('timeSetupTrend.a14')}}</a-button>
        <a-button type="primary" @click="showChart">{{$t('timeSetupTrend.a15')}}</a-button>
      </div>
      <a-modal
        :title="title"
        :width="1000"
        :visible="visible"
        :footer="null"
        @cancel="handleCancel"
        :destroyOnClose="destroyOnClose"
      >
      <my-timeSetupTrendChart :signalPoints="infoList" :axisformat="form"></my-timeSetupTrendChart>
      </a-modal>
      <a-modal
          :title="title"
          :width="1000"
          :visible="visibleList"
          :footer="null"
          @cancel="handleCancel"
        >
        <my-timeSetupTrendList :signalPoints="infoList" :axisformat="form"></my-timeSetupTrendList>
      </a-modal>
    </div>    
  </div>
</template>

<script>
import tabletitle from "../../../components/Title/tabletitle";
import navdar from "../../../components/Navdar/vertical";
import headerTitle from "../../../components/Title/headerTitle";
import nameUpdate from "../../../components/Name/nameUpdate";
import { lightTimetableListKT,getTimeTrendDeviceInfo } from "../../../api/device";
import select from "../../../components/Select/select";
import timeSetupTrendChart from "./timeSetupTrendChart";
import timeSetupTrendList from "./timeSetupTrendList";
import moment from 'moment';

export default {
  data() {
    return {
      title: this.$t('timeSetupTrend.a1'),
      visible:false,
      destroyOnClose:true,
      visibleList:false,
      list: [],
      arr: [],
      MAX_NUM: 1,
      timer: null,
      carriedOut: true,
      pos: 0,
      spinning: false,
      id: undefined,//包含类型和时间表ID
      loading: false,
      schType: 9,
      nodeId: undefined,//时间表ID
      nodeName: undefined,//时间表名称
      nodeParentName: undefined,//时间表父节点名称
      loadingOK: false,
      infoList:[],//设备信号列表
      countList:[],
      labelCol: { span: 4 },
      wrapperCol: { span: 12 },
      form:{
        date:moment(),
        time:moment('4:00', 'HH:mm'),
        dateString:"",
        timeString:"",
        timeView:this.$t('timeSetupTrend.a6'),
        formatList:[
          {
            key:1,
            name:this.$t('timeSetupTrend.a16'),
            unit: this.$t('timeSetupTrend.a11'),
            max:10,
            min:0,
            count:10,            
          },
          {
            key:2,
            name:this.$t('timeSetupTrend.a17'),
            unit: this.$t('timeSetupTrend.a11'),
            max:10,
            min:0,
            count:10,
          }
        ],
      },
      formatColumns:[
        {
          title: '',
          dataIndex: "name",
          width: 100,
          ellipsis: true,
        },  
        {
          title: this.$t('timeSetupTrend.a18'),
          scopedSlots: { customRender: 'unit' },
          width: 100,
          ellipsis: true,
        },
        {
          title: this.$t('timeSetupTrend.a19'),
          width: 100,
          scopedSlots: { customRender: 'max' },
          ellipsis: true,
        },
        {
          title: this.$t('timeSetupTrend.a20'),
          width: 100,
          scopedSlots: { customRender: 'min' },
          ellipsis: true,
        }, 
        {
          title: this.$t('timeSetupTrend.a21'),
          width: 100,
          scopedSlots: { customRender: 'count' },
          ellipsis: true,
        },
      ],      
      columns : [
        {
          title: this.$t('timeSetupTrend.a22'),
          dataIndex: "dvName",
          // width: 100,
          ellipsis: true,
        },
        {
          title: this.$t('timeSetupTrend.a23'),
          dataIndex: "signalName",
          // width: 100,
          ellipsis: true,
        },
        {
          title: this.$t('timeSetupTrend.a24'),
          dataIndex: "signalTypeDesc",
          // width: 80,
          ellipsis: true,
        },
        {
          title: "TagPoint",
          dataIndex: "tagPoint",
          // width: 80,
          ellipsis: true,
        },
        {
          title: this.$t('timeSetupTrend.a25'),
          // scopedSlots: { customRender: 'chart' },
          dataIndex: "chart",
          // width: 80,
          ellipsis: true,
        },
      ],
      units:[],
    };
  },
  // 获取侧边栏数据同时执行虚拟化列表的方法
  async mounted() {
    this.spinning = true;
    // 进入页面时调用获取页面左侧列表方法
    this.navderList();    
    this.spinning = false;
    this.countList.length=0;
    for(let i=1;i<=20;i++){
      this.countList.push({key:i,value:i})
    }
  },
  computed: {
    allrowSelection() {
      return {
        onChange: (selectedRowKeys, selectedRows) => {
          console.log('row select changed:', selectedRowKeys);
          this.chooseDateInfo = selectedRows;
        },
      };
    },
    // 侧边栏的高度计算和padding-top计算
    style: function () {
      if (this.pos !== 0) {
        return `height:${48 * this.arr.length}px;padding-top:${
          this.pos * 48 - 96
        }px`;
      } else {
        return `height:${48 * this.arr.length}px;padding-top:0px`;
      }
    },
    
  },
  //缓存页面勾子函数
  activated(){
    this.getdata();
  },
  watch: {
    // 监听侧边栏选择的id，并加载页面数据
    id: function (newQuestion, oldQuestion) {        
      this.getdata();
    },
    p1: function () {
      this.selectList = this.arr.slice(this.p1, this.p2);
    },
    '$route'(to,from){
      this.spinning = true;
      // 进入页面时调用获取页面左侧列表方法
      this.navderList();    
      this.spinning = false;
    },
  },
  methods: {
    moment,
    onDelete(key) {
      const dataSource = [...this.chooseDateList];
      this.chooseDateList = dataSource.filter(item => item.key !== key);
    },
    handleAdd() {
      const newData = {
        day: "",
        chart: "",
      };
      this.chooseDateList.push(newData);
    },
    //日期修改响应
    onChange(date, dateString) {
      console.log(date, dateString);
      // this.chooseDateInfo.day = 
    },
    //下拉选择响应
    handleChange(e){
      console.log('select changed', e);
    },
    // 获取页面左侧列表方法
    navderList() {
      this.spinning = true;
      let name = this.$route.name;
      if(name=="VRFAC_FAC_DATA_HIKAKU_TLEND" || name=="LIGHTING_FAC_DATA_HIKAKU_TLEND"||name=="ENVIRONMENT_FAC_DATA_HIKAKU_TLEND"||name=="FACILITIES_FAC_DATA_HIKAKU_TLEND")
        this.schType=11; 
      else if(name=="VRFAC_FAC_TIME_TLEND" || name=="LIGHTING_FAC_TIME_TLEND"||name=="ENVIRONMENT_FAC_TIME_TLEND"||name=="FACILITIES_FAC_TIME_TLEND")
        this.schType=12; 

       //CHW ADD 能源模块添加趋势数据
       console.log('navderList>>'+name);
      if(name=="ENE_DATA_HIKAKU_TREND")
        this.schType=11; 
      if(name=="ENE_TIME_TREND")
        this.schType=12; 
      //CHW ADD 能源模块添加趋势数据 
      let par = {
        siteId: this.$route.query.id,
        schType: this.schType,
      };
      lightTimetableListKT(par)
        .then((res) => {
          this.spinning = false;
          let { data } = res;
          data.key = "sub1";
          this.list.length=0;
          this.list.push(data);          
        })
        .catch((err) => {
          this.spinning = false;
          console.log(err);
        });
    },
    // 加载页面数据的方法       
    getdata() {
      this.loading = true;      
      let par = {
        siteId:this.$route.query.id,
        timeTrendGroupId: this.nodeId,
        timeTrendGroupName: this.nodeName
      }; 
      console.log("getTimeTrendDeviceInfo--参数：",par); 
      getTimeTrendDeviceInfo(par)
        .then((res) => {
          this.loading = false;
          let { data } = res;
          this.infoList.length=0;
          if(data.binaryInfoList!=null && data.binaryInfoList.length>0){
              for(let i=0;i<data.binaryInfoList.length;i++)
                this.infoList.push(data.binaryInfoList[i]);   
          }         
          if(data.analogInfoList!=null && data.analogInfoList.length>0)
            for(let i=0;i<data.analogInfoList.length;i++)
                this.infoList.push(data.analogInfoList[i]);
          if(data.multiStateInfoList!=null && data.multiStateInfoList.length>0)
            for(let i=0;i<data.multiStateInfoList.length;i++)
                this.infoList.push(data.multiStateInfoList[i]);
          if(data.accumulatorInfoList!=null && data.accumulatorInfoList.length>0)
            for(let i=0;i<data.accumulatorInfoList.length;i++)
                this.infoList.push(data.accumulatorInfoList[i]);
          if(data.calculateInfoList!=null && data.calculateInfoList.length>0)
            for(let i=0;i<data.calculateInfoList.length;i++)
                this.infoList.push(data.calculateInfoList[i]); 
          if(this.infoList.length>0){    
            for (let i = 0; i < this.infoList.length; i++) {
              this.infoList[i].key = i + 1;
              this.infoList[i].signalPoint = this.infoList[i].dvName+"--"+this.infoList[i].signalName;
              this.infoList[i].chart=this.getChartType(this.infoList[i].signalKind);
            }
          }
          console.log("getTimeTrendDeviceInfo--加载有效数据：",this.infoList);
        })
        .catch((err) => {
          this.loading = false;
          console.log(err);
        });
    },     
    getChartType(signalKind){
      let chartType="ON/OFF";
      if(signalKind!=null){
        switch (signalKind) {
          case "binary":
            chartType="ON/OFF";
            break;          
          case "multi":
            chartType="multiState";
            break;
          default:
            chartType="brokenLine";
            break;
        }
      }
      return chartType;
    },
    // 点击侧边栏获取页面详细数据加载id
    getMsgFormSon(data) {
      if(data==null)
        return;
      this.id = data;  
      let number = this.id.split(",");
      this.nodeId = number[1];
      let schedule = this.list[0].children[0].children;
      this.nodeParentName = this.list[0].children[0].title;
      for(var i=0;i<schedule.length;i++){
          if(this.id == schedule[i].key){
            this.nodeName = schedule[i].title;
            break;
          }
      }
      console.log("timeSetupTrend--获取选择项名称：",this.nodeParentName);      
    },
    // 虚拟化侧边栏列表
    virtual() {
      if (this.carriedOut) {
        let height = this.$refs.container.clientHeight;
        this.MAX_NUM = Math.ceil(height / 48);
        let scrollTop = this.$refs.container.scrollTop;
        this.pos = Math.round(scrollTop / 48);
        console.log("TimeTable-virtual",this.list);
        this.list[0].children[0].children = this.arr.slice(
          this.pos,
          this.pos + this.MAX_NUM
        );
        this.carriedOut = false;
        this.timer = setTimeout(() => {
          this.carriedOut = true;
          clearTimeout(this.timer);
        }, 50);
      }
    },
    // 图表显示
    showChart() {
      if(this.infoList==null||this.infoList.length==0){
        this.$message.error(this.$t('timeSetupTrend.a26'));
        return;
      } 
      this.form.dateString=moment(this.form.date).format('YYYY-MM-DD');
      this.form.timeString=moment(this.form.time).format('HH:mm');
      console.log("点击图表显示参数：",this.form);
      this.visible=true;
      // this.$router.push({
      //   path: "/homepage/light/timeSetupTrendChart",        
      //   query: { id: this.$route.query.id, nodeId: this.nodeId,nodeName:this.nodeName,schType:this.schType,
      //   signalPoints:this.infoList,par:this.form},
      // });
    },
    handleCancel(){
      this.visible = false;
      this.visibleList=false;
    },
    // 列表显示
    showList() {
      if(this.infoList==null||this.infoList.length==0){
        this.$message.error(this.$t('timeSetupTrend.a26'));
        return;
      }        

      this.form.dateString=moment(this.form.date).format('YYYY-MM-DD');
      this.form.timeString=moment(this.form.time).format('HH:mm');
      console.log("点击列表显示参数：",this.form);
      this.visibleList=true;
      // this.$router.push({
      //   path: "/homepage/air-condition/timeSetupTrendList",
      //   query: { id: this.$route.query.id, nodeId: this.nodeId,nodeName:this.nodeName,schType:this.schType,
      //   signalPoints:this.infoList,par:this.form},
      // });
    },        // 更新名称
    nameUpdate(name) {
      this.loadingOK = true;
      let par = {
        scheId: this.nodeId,
        scheType: this.schType,
        newName:name,
      };
      console.log(par);
      updateScheduleName(par)
        .then((res) => {
          this.loadingOK = false;
          this.$refs.children.changestate();
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },    
  },
  components: {
    "my-tabletitle": tabletitle,
    "my-navder": navdar,
    "my-headertitle": headerTitle,
    "my-nameupdate": nameUpdate,
    "my-select": select,
    "my-timeSetupTrendChart":timeSetupTrendChart,
    "my-timeSetupTrendList":timeSetupTrendList,
  },
};
</script>
<style scoped>
#timeSetupTrend {
  height: 100%;
  display: flex;
}
div /deep/.ant-modal-header {
  background-color: #7682ce;
}
.right {
  width: calc(100% - 250px);
  display: flex;
  flex-direction: column;
}
.aside {
  width: 250px;
  height: 100%;
  overflow: auto;
}
.main {
  width: 100%;
  height: 100%;
  overflow: auto;
}
.main-spinning {
  width: 100%;
  height: 100%;
  padding: 0px 20px 20px 20px;
  overflow: auto;
}
.main-show {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  color: rgba(12, 12, 12, 0.3);
  letter-spacing: 5px;
  white-space: nowrap;
}
.info {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px;
  font-size: 14px;
  margin-bottom: 20px;
  white-space: nowrap;
  overflow: hidden;
}
.info-input {
  width: 120px;
  margin-right: 15px;
}
.info-button {
  width: 80px;
  margin: 0px 10px;
}
.info-hidden {
  display: flex;
  height: 60px;
  align-items: center;
}
.footer {
  height: 55px;
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid rgba(12, 12, 12, 0.1);
  padding: 10px 10px;
}
button {
  width: 120px;
  margin: 0px 10px;
}
.slide-fade-enter-active {
  transition: all 0.8s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter,
.slide-fade-leave-to {
  transform: translateX(10px);
  opacity: 0;
}
.d {
  /* display: flex; */
  position: relative;
}
.c {
  position: absolute;
  width: 600px;
}
</style>